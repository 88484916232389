<template>
  <span>
    <navbar />
    <ribbon />
    <div class="container-fluid main-container" :class="additionalFooterClass">
      <router-view />
    </div>
    <FloatingMenu />
    <conversation-list v-if="authorizedUser" />
    <footer-view />
  </span>
</template>
<script>
import { mapState } from "vuex";
import FloatingMenu from "../components/FloatingMenu.vue";
import ConversationList from "../components/chat/ConversationList.vue";
import { initiateEcho } from "../utils/echo.js";

export default {
  name: "Authed",
  components: {
    FloatingMenu,
    ConversationList,
  },
  created() {
    initiateEcho();
  },
  computed: {
    ...mapState({
      authorizedUser: (state) => state.auth.user,
    }),
    additionalFooterClass: function () {
      return `${(this.$route.name || "").replace(/\./g, "-")}-main-container`;
    },
    isAllowed: function () {
      if (
        this.authorizedUser &&
        (this.authorizedUser.isAdmin || this.authorizedUser.isManagingAdmin)
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
